import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1600.000000 640.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)"
        >
       <path d="M1328 6368 c-2 -13 1 -122 8 -243 7 -121 13 -229 13 -241 1 -18 9
-22 49 -27 26 -4 116 -15 200 -27 392 -52 463 -61 542 -71 138 -16 125 -5 139
-116 23 -192 42 -452 61 -867 17 -364 20 -574 20 -1551 0 -979 -2 -1146 -16
-1255 -41 -322 -131 -529 -298 -687 -122 -117 -238 -186 -396 -239 -80 -26
-96 -28 -260 -28 -152 -1 -187 2 -265 22 -109 29 -233 80 -339 140 -66 38 -81
52 -96 86 -19 45 -62 149 -229 561 -100 248 -111 270 -135 273 -28 4 -35 -6
-236 -326 l-84 -133 13 -102 c21 -181 39 -323 96 -772 31 -242 59 -463 62
-492 l6 -52 76 -25 c215 -70 405 -106 876 -168 159 -21 604 -17 735 5 378 66
652 186 901 393 410 341 633 836 679 1509 6 78 10 687 10 1380 0 1384 9 1729
60 2369 5 71 13 112 21 117 8 4 45 13 84 19 38 7 109 19 157 29 l86 16 301
243 c208 168 301 249 301 263 0 19 -21 19 -1569 19 l-1569 0 -4 -22z"/>
<path d="M10831 2963 c-24 -60 -69 -171 -101 -248 -31 -77 -90 -221 -130 -320
-40 -99 -78 -193 -86 -210 -7 -16 -43 -104 -80 -195 -36 -91 -86 -212 -109
-270 -23 -58 -82 -202 -130 -320 -97 -241 -167 -395 -193 -423 -26 -29 -72
-44 -160 -52 l-82 -7 0 -49 0 -49 355 0 355 0 0 49 0 48 -98 5 c-170 10 -205
40 -177 152 17 65 121 333 137 353 8 10 91 12 351 10 l342 -2 56 -150 c31 -82
73 -193 93 -245 20 -52 36 -101 36 -107 0 -10 -36 -13 -145 -13 l-145 0 0 -50
0 -50 545 0 545 0 0 50 0 50 -109 0 c-75 0 -112 4 -118 13 -5 6 -103 260 -218
562 -114 303 -222 588 -241 635 -18 47 -94 247 -169 445 -75 198 -148 390
-162 428 l-26 67 -47 0 -46 0 -43 -107z m-83 -800 c28 -76 78 -210 112 -298
34 -88 75 -195 91 -237 l28 -78 -299 0 -299 0 14 38 c7 20 66 165 130 322 64
157 125 309 136 338 11 28 24 52 28 52 5 0 31 -62 59 -137z"/>
<path d="M14857 3063 c-3 -4 -78 -188 -167 -408 -89 -220 -177 -436 -195 -480
-36 -88 -151 -371 -252 -620 -196 -483 -224 -544 -264 -582 -34 -31 -62 -40
-156 -48 l-83 -7 0 -49 0 -49 355 0 355 0 0 48 0 49 -99 6 c-113 7 -168 27
-178 66 -10 41 11 117 79 287 l63 159 342 2 c258 2 343 -1 350 -10 6 -7 28
-61 51 -122 22 -60 61 -164 86 -230 26 -66 47 -126 49 -135 3 -18 7 -17 -153
-19 l-130 -1 0 -50 0 -50 543 0 542 0 0 50 0 50 -113 0 -112 0 -11 28 c-23 57
-167 434 -463 1217 -169 446 -315 830 -326 855 -18 43 -21 45 -63 48 -25 2
-47 -1 -50 -5z m-71 -1045 c52 -139 110 -291 128 -338 19 -47 37 -95 40 -107
l7 -23 -295 0 c-197 0 -296 3 -296 10 0 6 7 27 16 48 9 20 45 109 80 197 36
88 69 169 74 180 5 11 33 78 61 150 65 166 69 173 80 152 5 -8 52 -130 105
-269z"/>
<path d="M4647 3053 c-3 -5 -46 -109 -96 -233 -51 -124 -114 -279 -141 -345
-27 -66 -119 -293 -205 -505 -402 -989 -401 -987 -463 -1023 -23 -14 -60 -21
-123 -25 l-89 -5 0 -54 0 -53 358 2 357 3 0 50 0 50 -105 6 c-141 7 -180 28
-180 95 0 32 48 175 102 304 l44 105 348 0 349 0 18 -50 c9 -27 51 -140 93
-249 42 -110 76 -201 76 -203 0 -2 -65 -3 -145 -3 l-145 0 0 -55 0 -55 543 2
542 3 3 52 3 52 -118 3 -117 3 -53 140 c-30 77 -76 199 -103 270 -27 72 -83
220 -125 330 -42 110 -85 223 -95 250 -10 28 -53 140 -95 250 -42 110 -92 241
-110 290 -18 50 -68 180 -110 290 -42 110 -86 226 -97 258 l-20 57 -48 0 c-27
0 -51 -3 -53 -7z m-77 -1018 c27 -71 78 -208 114 -303 37 -95 66 -177 66 -183
0 -5 -118 -9 -295 -9 -162 0 -295 4 -295 9 0 9 43 117 190 476 45 110 88 220
97 244 l16 45 29 -75 c16 -41 51 -132 78 -204z"/>
<path d="M7630 2976 l0 -56 140 0 140 0 -2 -997 -3 -998 -137 -3 -138 -3 0
-50 0 -50 633 3 c614 4 635 4 737 27 195 42 295 98 452 249 241 234 352 500
352 847 0 295 -78 527 -246 730 -139 170 -306 269 -544 326 -85 21 -118 22
-736 26 l-648 5 0 -56z m1221 -71 c167 -35 243 -118 293 -319 63 -257 61
-1009 -4 -1308 -58 -267 -175 -344 -531 -350 l-154 -3 -3 998 -2 997 164 0
c109 0 189 -5 237 -15z"/>
<path d="M11990 2922 c0 -60 -3 -224 -7 -365 l-6 -257 51 0 50 0 12 53 c39
184 126 365 218 454 70 68 197 118 283 111 l40 -3 -1 -995 0 -995 -165 -3
-165 -2 0 -50 0 -50 605 0 605 0 0 50 0 50 -165 0 -165 0 0 1000 0 1000 48 0
c155 -1 301 -98 385 -257 39 -75 100 -248 112 -320 l7 -43 49 0 49 0 0 178 c0
97 -3 261 -7 365 l-6 187 -914 0 -913 0 0 -108z"/>
<path d="M5766 2848 c-3 -95 -8 -257 -11 -360 l-5 -188 53 0 54 0 17 78 c72
327 235 517 458 530 l78 5 0 -997 0 -996 -165 0 -165 0 0 -55 0 -55 603 2 602
3 0 50 0 50 -165 5 -165 5 -3 994 -2 994 84 -5 c113 -7 179 -38 260 -121 93
-94 161 -243 210 -459 6 -26 10 -28 58 -28 l51 0 -6 212 c-4 116 -7 278 -7
360 l0 148 -914 0 -914 0 -6 -172z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
